import { type Batch, type BatchOverview } from '@autone/openapi-buying';
import { encodeUrl } from '@autone/ui';

import { buyingApi } from '../apis';

export type EditableComparables = Partial<Batch['comparables']>;
export type BatchWithEditableComparables = Omit<Batch, 'comparables'> & {
  comparables: EditableComparables;
};

export const buysApis = buyingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatches: builder.query<BatchOverview[], void>({
      query: () => `batch/overview`,
      providesTags: ['Batches'], // Specify tags for caching and invalidation
      transformResponse: (response: { batches: BatchOverview[] }) =>
        response.batches,
    }),
    getBatch: builder.query<
      BatchWithEditableComparables,
      {
        batchId: Batch['id'];
      }
    >({
      query: ({ batchId }) =>
        encodeUrl({ url: `batch/{batchId}`, variables: { batchId } }),
      transformResponse: (response: { batch: Batch }) => response.batch,
      providesTags: ['Batch'],
    }),
    patchBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
        name?: string;
        otb_value?: number;
        otb_currency?: string;
        step?: Batch['step'];
        sell_period_start?: string;
        sell_period_end?: string;
        sell_through_target?: number;
        sku_capacity_target?: number;
        otb_import_job_id?: string;
        hideSnackbar?: boolean;
        otb_price_type?: Batch['otb']['price_type'];
        comparables?: Batch['comparables'];
      }
    >({
      query: ({
        batchId,
        otb_value,
        otb_currency,
        name,
        step,
        sell_period_start,
        sell_period_end,
        sell_through_target,
        sku_capacity_target,
        otb_import_job_id,
        hideSnackbar,
        otb_price_type,
        comparables,
      }) => {
        return {
          url: encodeUrl({ url: `batch/{batchId}`, variables: { batchId } }),
          extraArgs: { hideSnackbar: hideSnackbar || false },
          method: 'PATCH',
          body: {
            batch: {
              otb: {
                currency: otb_currency,
                value: otb_value,
                import_job_id: otb_import_job_id,
                price_type: otb_price_type,
              },
              name,
              step,
              sell_period_start,
              sell_period_end,
              sell_through_target,
              sku_capacity_target,
              comparables,
            },
          },
        };
      },
      async onQueryStarted(
        { batchId, step, name },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          buysApis.util.updateQueryData('getBatch', { batchId }, (draft) => {
            if (step) {
              draft.step = step;
            }
            if (name) {
              draft.name = name;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch (_) {
          patchResult.undo();
        }
      },
      transformResponse: (response: { batch: Batch }) => response.batch,
      invalidatesTags: ['Batches', 'AssortmentMismatches'],
    }),
    renameBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
        name: Batch['name'];
      }
    >({
      invalidatesTags: ['Batch'],
      query: ({ batchId, name }) => {
        return {
          url: encodeUrl({ url: `batch/{batchId}`, variables: { batchId } }),
          method: 'PATCH',
          body: {
            batch: {
              name,
            },
          },
        };
      },
      async onQueryStarted({ batchId, name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buysApis.util.updateQueryData('getBatches', undefined, (draft) => {
            draft.forEach((item) => {
              if (item.batch.id === batchId) {
                item.batch.name = name;
              }
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformResponse: (response: { batch: Batch }) => response.batch,
    }),
    deleteBatch: builder.mutation<Batch, { batchId: Batch['id'] }>({
      query: ({ batchId }) => ({
        url: encodeUrl({ url: `batch/{batchId}`, variables: { batchId } }),
        method: 'DELETE',
      }),
      async onQueryStarted({ batchId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buysApis.util.updateQueryData('getBatches', undefined, (draft) => {
            draft.forEach((buy) => {
              if (buy.batch.id === batchId) {
                buy.batch.status = 'deleted';
              }
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    restoreBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
      }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/restore`,
          variables: { batchId },
        }),
        method: 'POST',
      }),
      async onQueryStarted({ batchId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buysApis.util.updateQueryData('getBatches', undefined, (draft) => {
            draft.forEach((buy) => {
              if (buy.batch.id === batchId) {
                buy.batch.status = 'active';
              }
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetBatchesQuery,
  useGetBatchQuery,
  useDeleteBatchMutation,
  useRestoreBatchMutation,
  useRenameBatchMutation,
  usePatchBatchMutation,
} = buysApis;
