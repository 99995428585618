import { screamingCase } from '@autone/utils';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { GenericErrorSvg } from '../../components/error/GenericErrorSvg';
import { SpecificErrorSvg } from '../../components/error/SpecificErrorSvg';
import Page from '../../components/page';
import { type ModuleName } from '../../types';
import { isKeyInType } from '../../types/guards';

const ErrorDetail = ({
  module,
  svg,
  header,
  description,
  emailSubject,
  buttonText,
}: {
  module: ModuleName;
  svg: JSX.Element;
  header: string;
  description: string;
  emailSubject: string;
  buttonText: string;
}) => {
  return (
    <>
      <Grid item xs={12} justifyContent={'center'} display={'flex'}>
        {svg}
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={'center'} spacing={4}>
          <Stack sx={{ textAlign: 'center' }} spacing={1}>
            <Typography variant="h1">{header}</Typography>
            <Typography
              variant="h3"
              sx={{ fontWeight: 'normal', maxWidth: '700px' }}
            >
              {description}
            </Typography>
          </Stack>
          <Button
            variant="contained"
            size="large"
            sx={{ textTransform: 'none', width: 'fit-content' }}
            onClick={() =>
              (window.location.href = `mailto:hello@autone.io,harry@autone.io,mariana@autone.io?subject= ${
                module || 'autone.io'
              } | ${emailSubject}`)
            }
          >
            {buttonText}
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

const ErrorView = ({
  module,
  errorCodes,
  text,
}: {
  module: ModuleName;
  errorCodes?: Record<string, { header: string; description: string }>;
  text?: {
    header: string;
    description: string;
    contactUs: string;
  };
}) => {
  const { reasonCode, batchId } =
    useParams<
      | {
          reasonCode?: string;
          batchId?: string;
        }
      | undefined
    >() || {};

  const code = reasonCode ? screamingCase(reasonCode) : 'UNKNOWN';

  const { header, description } =
    errorCodes && isKeyInType(errorCodes, code)
      ? errorCodes[code]
      : {
          header: text?.header || 'Oops, that’s our bad.',
          description:
            text?.description ||
            'We’re not exactly sure what happened, but our servers say something is wrong.',
        };

  const emailSubject = `Error ${
    reasonCode
      ? '| Reason Code: ' + reasonCode
      : '| Reason Code: unknown reason'
  } ${batchId ? '| Batch ID: ' + batchId : ''}`;

  return (
    <Page
      title={`autone.io | ${header}`}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container justifyContent="center" spacing={2}>
        <ErrorDetail
          module={module}
          svg={reasonCode ? <SpecificErrorSvg /> : <GenericErrorSvg />}
          header={header}
          description={description}
          emailSubject={emailSubject}
          buttonText={text?.contactUs || 'Contact us'}
        />
      </Grid>
    </Page>
  );
};

export default ErrorView;
