import { type Disabled } from '../../types';

import { CanAccess } from './';

type IsDisabled = {
  submitted: boolean;
  token: string;
  permissions: Array<string>;
  noAccessText: string;
  submittedText?: string;
};

export const isDisabled = ({
  submitted,
  token,
  permissions,
  noAccessText,
  submittedText,
}: IsDisabled): Disabled => {
  // if user has a token and cannot access due to permissions
  // we set disabled to true and return the correct tooltip message

  if (token && !CanAccess(token, permissions)) {
    return {
      disabled: true,
      disabledText: noAccessText,
    };
  }

  // if a submission has been made then no more edits can be done so disable edits
  if (submitted) {
    return { disabled: true, disabledText: submittedText };
  }

  return { disabled: false, disabledText: undefined };
};
