import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import { type MixpanelActions } from '../../types';

import {
  type BUTTON_CLICK,
  type DROPDOWN_SELECT,
  type EXPORT,
  type TEXT_CHANGE,
  type TOGGLE,
  VIEW_ACCESSED,
} from './eventTypes';

const { REACT_APP_MIXPANEL_TOKEN, REACT_APP_MIXPANEL_MODULE } = process.env;

mixpanel.init(REACT_APP_MIXPANEL_TOKEN || '');

const actions: MixpanelActions = {
  identify: (id) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.identify(id);
    }
  },

  alias: (id) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.track(name, { ...props, module: REACT_APP_MIXPANEL_MODULE });
    }
  },
  people: {
    set: (props) => {
      if (REACT_APP_MIXPANEL_TOKEN && typeof props !== 'string') {
        mixpanel.people.set(props);
      }
    },
  },
};

export const Mixpanel = actions;
export * from './eventTypes';
export * from './pivotEvents';

/**
 * @deprecated This method is deprecated in favor of the {@link trackMixpanelEvent} method.
 */
export const trackMixpanelAction = (
  actionName: string,
  actionProperties?: Record<string, unknown> & {
    app?: 'replenishment' | 'rebalancing' | 'reorder' | 'events';
  },
) => Mixpanel.track(actionName, actionProperties);

export const trackMixpanelEvent = (
  action:
    | typeof BUTTON_CLICK
    | typeof TEXT_CHANGE
    | typeof EXPORT
    | typeof TOGGLE
    | typeof DROPDOWN_SELECT,
  eventProperties?: Record<string, unknown> & {
    app:
      | 'replenishment'
      | 'rebalancing'
      | 'reorder'
      | 'events'
      | 'parameters'
      | 'hub'
      | 'assortment';
    event: string;
    value?: string | number | null;
  },
) => Mixpanel.track(action, eventProperties);

export type TrackedMixpanelRModuleViews =
  | 'overview'
  | 'new batch'
  | 'ongoing batches table'
  | 'upcoming batches table'
  | 'submitted batches table'
  | 'review batch'
  | 'locations table'
  | 'pivot main table'
  | 'pivot edit table'
  | 'receiving table'
  | 'sending table'
  | 'trips table'
  | 'products table'
  | 'product sizes table'
  | 'stock analysis table'
  | 'location sizes table'
  | 'departments table'
  | 'high level view'
  | 'product insights'
  | 'size edit'
  | 'parameters'
  | 'batch summary department table'
  | 'batch summary locations table'
  | 'archived batches table'
  | 'failed batches table';

export const useTrackMixpanelRModuleViews = ({
  app,
  view,
  viewMode,
}: {
  app: 'replenishment' | 'rebalancing' | 'reorder';
  viewMode: 'main' | 'sidepanel' | 'dialog';
  view: TrackedMixpanelRModuleViews;
}) => {
  useEffect(() => {
    Mixpanel.track(VIEW_ACCESSED, {
      app,
      view,
      viewMode,
    });
  }, [app, view, viewMode]);
};
