import { type AutoneBackendError } from './type-guards/isAutoneBackendError';

/**
 * Format an `AutoneBackendError` as a markdown string.
 */
export const formatAutoneBackendErrorAsMd = (
  error: AutoneBackendError,
): string => {
  // please do not modify indentation as it will be captured as whitespace in the markdown
  const message = `
**error code**: ${error.error_code}
**detail**: ${error.detail ?? 'no detail provided'}

**body**
\`\`\`json
${JSON.stringify(error.body ?? {}, null, 2)}
\`\`\`
`;

  return message;
};
