import { amplifyCookieStorageConfig } from '@autone/ui';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import '@autone/tailwind-config/global.css';

import App from './App';
// central AWS config file
import config from './config/aws-exports';
// redux store
import { store } from './redux/store';

const MUI_X_LICENSE_KEY =
  '43bd9397661b3d847d0001eec6cb5c9dTz0xMDIwNzcsRT0xNzYzNjUyNTUyMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=';

// // set the mui x license key for the date picker
LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

// configure amplify
Amplify.configure({
  ...config,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage(amplifyCookieStorageConfig),
);

// configure sentry
Sentry.init({
  dsn: 'https://6c20fc13c88f4a00a2d1f1ff4fb75ace@o1115656.ingest.sentry.io/6743590',
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment:
    process.env.NODE_ENV === 'development'
      ? 'localhost'
      : process.env.REACT_APP_BUILD_STAGE,
});

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.REACT_APP_START_MOCK_WORKER === 'true') {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start();
    })
    .then(() => {
      root.render(
        <Provider store={store}>
          <App />
        </Provider>,
      );
    });
} else {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
