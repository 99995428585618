// @ts-nocheck
import { isDisabled } from '../utils';

import useUserToken from './useUserToken';

interface UsePermissionsProps {
  isSubmitted: boolean;
  standardPermission: string;
  adminPermission: string;
  noAccessText: string;
  submittedText: string;
}

const usePermissions = ({
  isSubmitted,
  standardPermission,
  adminPermission,
  noAccessText,
  submittedText,
}: UsePermissionsProps) => {
  const { token } = useUserToken();

  const isReadOnly = isDisabled({
    submitted: isSubmitted,
    token,
    permissions: [standardPermission, adminPermission],
    noAccessText,
    submittedText,
  });

  const isNotAnAdmin = isDisabled({
    submitted: isSubmitted,
    token,
    permissions: [adminPermission],
    noAccessText,
    submittedText,
  });

  // whether the user is an admin without taking submission into account
  const isAdminExclSubmission = isDisabled({
    submitted: false,
    token,
    permissions: [adminPermission],
    noAccessText,
  });

  return {
    isReadOnly,
    isNotAnAdmin,
    isAdminExclSubmission,
  };
};

export default usePermissions;
