import type React from 'react';
import { type RefObject } from 'react';
import { useEffect, useState } from 'react';

import { type DataItem } from '../types';

const useTableDynamicWidth = <TDataItem extends DataItem>({
  data,
  ref,
}: {
  data?: TDataItem[];
  ref: RefObject<HTMLDivElement>;
}) => {
  const [scrollLeft, setScrollLeft] = useState<number | null>(null);
  const [tableWidth, setTableWidth] = useState<number | null>(null);
  const [isScrollingHorizontally, setIsScrollingHorizontally] =
    useState<boolean>(false);

  const handleSetScrollLeft = (e: React.UIEvent<HTMLElement>) => {
    const newScrollLeft = e.currentTarget.scrollLeft;
    if (newScrollLeft === scrollLeft) return;
    setScrollLeft(newScrollLeft);
    setIsScrollingHorizontally(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsScrollingHorizontally(false);
    }, 1000);
  });

  // get the initial width of the table
  useEffect(() => {
    if (data) {
      handleResizeCallback(ref);
    }
  }, [data]);

  const handleResizeCallback = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      setTableWidth(width);
    }
  };

  return {
    scrollLeft,
    tableWidth,
    handleResizeCallback,
    handleSetScrollLeft,
    isScrollingHorizontally,
  };
};

export default useTableDynamicWidth;
