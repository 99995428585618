export const GenericErrorSvg = () => (
  <svg
    width="300"
    height="284"
    viewBox="0 0 300 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.245 78.8253C164.032 78.8253 160.127 76.6158 157.958 73.0041L154.406 67.0903C147.474 55.5501 135.994 47.3988 122.629 45.697C111.709 44.3064 97.7293 43 83.75 43C73.9942 43 64.2383 43.6362 55.522 44.4933C36.3107 46.3825 21.8192 61.345 20.3427 80.5722C19.0921 96.8584 18 119.849 18 150.476C18 172.216 18.7924 191.582 19.8002 207.42C21.4855 233.904 42.1019 254.292 68.6235 255.819C88.7665 256.979 115.725 257.952 149.5 257.952C183.275 257.952 210.234 256.979 230.377 255.819C256.898 254.292 277.515 233.904 279.2 207.42C280.208 191.582 281 172.216 281 150.476C281 138.043 280.28 126.329 279.34 116.333C277.534 97.1336 262.672 82.2873 243.453 80.4836C233.445 79.5445 221.719 78.8253 209.273 78.8253H168.245Z"
      fill="#FFC7C7"
      stroke="#FF2222"
      strokeWidth="2"
    />
    <line
      x1="64.1644"
      y1="81.8877"
      x2="46.6602"
      y2="64.3835"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="79.1219"
      y1="81.8877"
      x2="61.6177"
      y2="64.3835"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="94.079"
      y1="81.8877"
      x2="76.5748"
      y2="64.3835"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="231.307"
      y1="201.229"
      x2="252.429"
      y2="201.229"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="231.307"
      y1="213.842"
      x2="247.7"
      y2="213.842"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="231.307"
      y1="226.455"
      x2="247.7"
      y2="226.455"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M120.709 160.952C121.215 160.952 121.692 161.429 121.692 161.935V170.208C121.692 170.481 121.583 170.727 121.364 170.945C121.201 171.109 120.982 171.191 120.709 171.191H117.187C116.914 171.191 116.777 171.328 116.777 171.601V184.953C116.777 185.226 116.668 185.471 116.45 185.69C116.286 185.854 116.067 185.936 115.794 185.936H106.21C105.686 185.936 105.227 185.477 105.227 184.953V171.601C105.227 171.328 105.091 171.191 104.818 171.191H79.6705C79.1456 171.191 78.6875 170.733 78.6875 170.208V163.655C78.6875 163.327 78.7694 162.918 78.9332 162.426L93.6776 129.333C93.8961 128.842 94.2783 128.596 94.8244 128.596H105.064C105.627 128.596 106.026 129.206 105.808 129.726C105.806 129.732 105.803 129.737 105.801 129.743L92.1213 160.461C92.0667 160.57 92.0667 160.679 92.1213 160.788C92.1759 160.897 92.2578 160.952 92.367 160.952H104.818C105.091 160.952 105.227 160.815 105.227 160.542V150.385C105.227 150.112 105.309 149.894 105.473 149.73C105.692 149.511 105.937 149.402 106.21 149.402H115.794C116.3 149.402 116.777 149.88 116.777 150.385V160.542C116.777 160.815 116.914 160.952 117.187 160.952H120.709Z"
      fill="white"
    />
    <path
      d="M148.844 186.837C142.837 186.837 138.086 185.171 134.591 181.84C131.096 178.454 129.348 173.867 129.348 168.078V146.453C129.348 140.774 131.096 136.269 134.591 132.938C138.086 129.606 142.837 127.941 148.844 127.941C154.851 127.941 159.602 129.606 163.097 132.938C166.646 136.269 168.421 140.774 168.421 146.453V168.078C168.421 173.867 166.646 178.454 163.097 181.84C159.602 185.171 154.851 186.837 148.844 186.837ZM148.844 176.925C151.301 176.925 153.24 176.188 154.66 174.713C156.134 173.184 156.871 171.164 156.871 168.652V146.126C156.871 143.614 156.134 141.62 154.66 140.146C153.24 138.617 151.301 137.852 148.844 137.852C146.441 137.852 144.502 138.617 143.028 140.146C141.608 141.62 140.898 143.614 140.898 146.126V168.652C140.898 171.164 141.608 173.184 143.028 174.713C144.502 176.188 146.441 176.925 148.844 176.925Z"
      fill="white"
    />
    <path
      d="M217.021 160.952C217.527 160.952 218.004 161.429 218.004 161.935V170.208C218.004 170.481 217.895 170.727 217.677 170.945C217.513 171.109 217.294 171.191 217.021 171.191H213.499C213.226 171.191 213.089 171.328 213.089 171.601V184.953C213.089 185.226 212.98 185.471 212.762 185.69C212.598 185.854 212.379 185.936 212.106 185.936H202.523C201.998 185.936 201.54 185.477 201.54 184.953V171.601C201.54 171.328 201.403 171.191 201.13 171.191H175.983C175.458 171.191 175 170.733 175 170.208V163.655C175 163.327 175.082 162.918 175.245 162.426L189.99 129.333C190.208 128.842 190.591 128.596 191.137 128.596H201.376C201.94 128.596 202.338 129.206 202.12 129.726C202.118 129.732 202.115 129.737 202.113 129.743L188.433 160.461C188.379 160.57 188.379 160.679 188.433 160.788C188.488 160.897 188.57 160.952 188.679 160.952H201.13C201.403 160.952 201.54 160.815 201.54 160.542V150.385C201.54 150.112 201.622 149.894 201.785 149.73C202.004 149.511 202.25 149.402 202.523 149.402H212.106C212.612 149.402 213.089 149.88 213.089 150.385V160.542C213.089 160.815 213.226 160.952 213.499 160.952H217.021Z"
      fill="white"
    />
    <path
      d="M120.709 160.952C121.215 160.952 121.692 161.429 121.692 161.935V170.208C121.692 170.481 121.583 170.727 121.364 170.945C121.201 171.109 120.982 171.191 120.709 171.191H117.187C116.914 171.191 116.777 171.328 116.777 171.601V184.953C116.777 185.226 116.668 185.471 116.45 185.69C116.286 185.854 116.067 185.936 115.794 185.936H106.21C105.686 185.936 105.227 185.477 105.227 184.953V171.601C105.227 171.328 105.091 171.191 104.818 171.191H79.6705C79.1456 171.191 78.6875 170.733 78.6875 170.208V163.655C78.6875 163.327 78.7694 162.918 78.9332 162.426L93.6776 129.333C93.8961 128.842 94.2783 128.596 94.8244 128.596H105.064C105.627 128.596 106.026 129.206 105.808 129.726C105.806 129.732 105.803 129.737 105.801 129.743L92.1213 160.461C92.0667 160.57 92.0667 160.679 92.1213 160.788C92.1759 160.897 92.2578 160.952 92.367 160.952H104.818C105.091 160.952 105.227 160.815 105.227 160.542V150.385C105.227 150.112 105.309 149.894 105.473 149.73C105.692 149.511 105.937 149.402 106.21 149.402H115.794C116.3 149.402 116.777 149.88 116.777 150.385V160.542C116.777 160.815 116.914 160.952 117.187 160.952H120.709Z"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M148.844 186.837C142.837 186.837 138.086 185.171 134.591 181.84C131.096 178.454 129.348 173.867 129.348 168.078V146.453C129.348 140.774 131.096 136.269 134.591 132.938C138.086 129.606 142.837 127.941 148.844 127.941C154.851 127.941 159.602 129.606 163.097 132.938C166.646 136.269 168.421 140.774 168.421 146.453V168.078C168.421 173.867 166.646 178.454 163.097 181.84C159.602 185.171 154.851 186.837 148.844 186.837ZM148.844 176.925C151.301 176.925 153.24 176.188 154.66 174.713C156.134 173.184 156.871 171.164 156.871 168.652V146.126C156.871 143.614 156.134 141.62 154.66 140.146C153.24 138.617 151.301 137.852 148.844 137.852C146.441 137.852 144.502 138.617 143.028 140.146C141.608 141.62 140.898 143.614 140.898 146.126V168.652C140.898 171.164 141.608 173.184 143.028 174.713C144.502 176.188 146.441 176.925 148.844 176.925Z"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M217.021 160.952C217.527 160.952 218.004 161.429 218.004 161.935V170.208C218.004 170.481 217.895 170.727 217.677 170.945C217.513 171.109 217.294 171.191 217.021 171.191H213.499C213.226 171.191 213.089 171.328 213.089 171.601V184.953C213.089 185.226 212.98 185.471 212.762 185.69C212.598 185.854 212.379 185.936 212.106 185.936H202.523C201.998 185.936 201.54 185.477 201.54 184.953V171.601C201.54 171.328 201.403 171.191 201.13 171.191H175.983C175.458 171.191 175 170.733 175 170.208V163.655C175 163.327 175.082 162.918 175.245 162.426L189.99 129.333C190.208 128.842 190.591 128.596 191.137 128.596H201.376C201.94 128.596 202.338 129.206 202.12 129.726C202.118 129.732 202.115 129.737 202.113 129.743L188.433 160.461C188.379 160.57 188.379 160.679 188.433 160.788C188.488 160.897 188.57 160.952 188.679 160.952H201.13C201.403 160.952 201.54 160.815 201.54 160.542V150.385C201.54 150.112 201.622 149.894 201.785 149.73C202.004 149.511 202.25 149.402 202.523 149.402H212.106C212.612 149.402 213.089 149.88 213.089 150.385V160.542C213.089 160.815 213.226 160.952 213.499 160.952H217.021Z"
      stroke="#FF2222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
