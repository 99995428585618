import { type SlackWebhookUrl } from './types';

const isSlackWebhookUrl = (
  webhookUrl: string,
): webhookUrl is SlackWebhookUrl<string> => {
  return webhookUrl.startsWith('https://hooks.slack.com/services/');
};

/**
 * @returns Retrieves the Slack Incoming Webhook URL for either the `#platform-alerts-dev`
 * or `#platform-alerts-prod` channels.
 */
export const getPlatformAlertsSlackWebhookUrl = (): SlackWebhookUrl<string> => {
  const webhookUrl = process.env.REACT_APP_SLACK_PLATFORM_ALERTS_WEBHOOK_URL;
  if (!webhookUrl || webhookUrl.length === 0) {
    throw new Error('REACT_APP_SLACK_PLATFORM_ALERTS_WEBHOOK_URL is undefined');
  }
  if (!isSlackWebhookUrl(webhookUrl)) {
    throw new Error(
      'REACT_APP_SLACK_PLATFORM_ALERTS_WEBHOOK_URL is not a valid Slack webhook URL',
    );
  }
  return webhookUrl;
};
