import { z } from 'zod';

export { emailError, passwordError } from './helpError';
export { default as errorSnackBar } from './errorSnackBar';
export { default as c } from './errorMessages';
export { default as AuthError, isAuthError } from './AuthError';
export type { ErrorType } from './types';

const ErrorResponseSchema = z.object({
  data: z
    .object({
      error_code: z.string(),
      detail: z.string(),
    })
    .partial()
    .nullable(),
  status: z.number(),
});

/**
 * @deprecated Use `isFetchBaseQueryError` and check the `data` field with
 * `isAutoneBackendError` instead.
 */
export const isErrorResponse = (
  error: unknown,
): error is z.infer<typeof ErrorResponseSchema> => {
  return ErrorResponseSchema.safeParse(error).success;
};
