import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFilterList = (props) => {
  const {
    options,
    defaultOpen,
    numOptionsDefaultOpen,
    handleFilterClickCallback,
    type,
  } = props || {};

  const [anchorEl, setAnchorEl] = useState(null);

  const pathname = useLocation();

  const [itemsPerPage] = useState(50);
  const [page, setPage] = useState(1);

  const [show, setShow] = useState(
    (options && options.length <= numOptionsDefaultOpen) || defaultOpen,
  );

  const handleShow = () => setShow((show) => !show);

  const handleClose = () => setAnchorEl(null);
  const openFilter = Boolean(anchorEl);

  const handleFilterClick = async (dimension, id, active, description) =>
    handleFilterClickCallback(dimension, id, active, type, description);

  useEffect(() => {
    if (openFilter) {
      handleClose();
    }
  }, [pathname]);

  const loadMoreItems = () => setPage((prevPage) => prevPage + 1);

  return {
    loadMoreItems,
    itemsPerPage,
    page,
    handleShow,
    show,
    handleFilterClick,
  };
};

export default useFilterList;
