import { useEffect, useRef, useState } from 'react';

const useLocalStorage = <T>({
  key,
  defaultValue,
}: {
  key: string | null;
  defaultValue: T;
}) => {
  const prevDefaultValueRef = useRef<T>();

  useEffect(() => {
    prevDefaultValueRef.current = defaultValue;
  }, [defaultValue]);

  const prevDefaultValue = prevDefaultValueRef.current;

  const getLocalStorage = (): T => {
    if (!key) return defaultValue;

    try {
      const rawValue = localStorage.getItem(key);
      if (rawValue == null) {
        return defaultValue;
      }

      return JSON.parse(rawValue);
    } catch (_) {
      return defaultValue;
    }
  };

  const setLocalStorage = (data: T): void => {
    if (!key) return setLocalStorageState(data);

    try {
      const newValue = JSON.stringify(data);
      localStorage.setItem(key, newValue);
      setLocalStorageState(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Error setting localStorage key “${key}”:`, e);
    }
  };

  const [localStorageState, setLocalStorageState] = useState(() =>
    getLocalStorage(),
  );

  useEffect(() => {
    if (JSON.stringify(prevDefaultValue) !== JSON.stringify(defaultValue)) {
      setLocalStorage(getLocalStorage());
    }
  }, [prevDefaultValue, defaultValue]);

  const clearLocalStorage = (): void => {
    if (!key) return;
    localStorage.removeItem(key);
  };

  return {
    getLocalStorage,
    setLocalStorage,
    clearLocalStorage,
    localStorageState,
  };
};

export default useLocalStorage;
