import { useEffect } from 'react';

const useDebounceEffect = (effect: () => void, deps: any[], delay = 500) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};

export default useDebounceEffect;
