import { type RefObject, useEffect } from 'react';

const useTableResize = <T>({
  ref,
  handleResizeCallback = () => {},
}: {
  ref: RefObject<T> | null;

  handleResizeCallback: (ref: RefObject<T>) => void;
}) => {
  useEffect(() => {
    const resizeListener = () => {
      if (ref) {
        handleResizeCallback(ref);
      }
    };

    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [ref]);

  return {};
};

export default useTableResize;
