import { capitalizeFirstLetter } from '@autone/utils';
import {
  Checkbox,
  type CustomTheme,
  FormControlLabel,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';

import { generateFilterTitle } from '../../utils';

type FilterItemType = {
  filterItemData: {
    id?: string | null;
    title: string | null;
    active?: boolean | null;
  };
  handleFilterClick: (
    dimension?: string,
    id?: string | null,
    active?: boolean | null,
    title?: string | null,
  ) => void;
  dimension: string;
  filterItemSx?: SxProps;
};

const FilterItem = ({
  filterItemData,
  handleFilterClick,
  dimension,
  filterItemSx,
}: FilterItemType) => {
  const theme = useTheme<CustomTheme>();

  const { active, id, title } = filterItemData;

  return (
    <FormControlLabel
      sx={{
        textTransform: 'none',
        borderRadius: `${theme.shape.borderRadius}px`,
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        // also adding min height as with overflow hidden, lots of items in a filter list were reducing the height of each item significantly
        minHeight: 35,
        height: 35,
        '&:hover': {
          background: '#f0f5fd',
        },
        ...filterItemSx,
      }}
      control={
        <Checkbox
          sx={{
            '&&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => handleFilterClick(dimension, id, active, title)}
          checked={active || false}
        />
      }
      label={
        <Typography noWrap>
          {capitalizeFirstLetter(generateFilterTitle(title || id || ''))}
        </Typography>
      }
    />
  );
};

export default FilterItem;
