/**
 * This code is copied from this small NPM library:
 *
 * https://www.npmjs.com/package/async-await-mutex-lock?activeTab=code
 *
 * It's a simple async lock that ensures only one async function call
 * can access a critical section at a time.
 */
export class Lock<T> {
  private _isAcquired = false;
  private _acquiredMap: Map<T, boolean> = new Map<T, boolean>();
  private waitingMap: Map<T, (() => void)[]> = new Map<T, (() => void)[]>();
  private waitingList: (() => void)[] = [];

  acquire(key?: T): Promise<void> {
    if (key) {
      // if the lock has never been acquired for this key
      if (!this._acquiredMap.has(key) || !this._acquiredMap.get(key)) {
        this._acquiredMap.set(key, true);
        return Promise.resolve();
      }
    } else if (!this._isAcquired) {
      this._isAcquired = true;
      return Promise.resolve();
    }

    return new Promise((resolve, _) => {
      if (key) {
        if (this.waitingMap.has(key)) {
          // get a list of promise resolvers for the key
          const resolvers = this.waitingMap.get(key);
          if (resolvers) {
            // add the new promise resolver to the waiting list
            resolvers.push(resolve);
            this.waitingMap.set(key, resolvers);
          }
        } else {
          // create a new waiting list
          this.waitingMap.set(key, [resolve]);
        }
      } else {
        this.waitingList.push(resolve);
      }
    });
  }

  isAcquired(key?: T): boolean {
    if (key) {
      if (!this._acquiredMap.has(key)) {
        return false;
      } else {
        return this._acquiredMap.get(key) ?? false;
      }
    } else {
      return this._isAcquired;
    }
  }

  release(key?: T): void {
    // if using key
    if (key) {
      if (!this._acquiredMap.has(key) || !this._acquiredMap.get(key)) {
        throw new Error(
          'Please acquire a lock for ' + key + ' before releasing!!',
        );
      } else {
        if (this.waitingMap.get(key)?.length ?? 0 > 0) {
          // get the promise resolver for the next waiting function
          const resolve = this.waitingMap.get(key)?.shift();
          if (resolve) {
            // resolve the promise for the next function to release the lock
            resolve();
          }
        } else {
          if (this.waitingMap.has(key)) {
            this.waitingMap.delete(key);
          }

          this._acquiredMap.set(key, false);
        }
      }

      // if not using key
    } else {
      if (!this._isAcquired) {
        throw new Error('Please acquire a lock before releasing!!');
      } else {
        if (this.waitingList.length > 0) {
          const resolve = this.waitingList.shift();
          if (resolve) {
            resolve();
          }
        } else {
          this._isAcquired = false;
        }
      }
    }
  }
}
