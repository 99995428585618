import { type AssortmentEditable } from '@autone/openapi-buying';

import { SELECTED_OPTIONS } from '../../../features/assortment/constants';
import { type BuyingFilters } from '../../../types/filters';
import { assortmentApi } from '../../services/assortment/assortment';
import { type AppDispatch } from '../../store';

export const updateAssortmentTableCache = ({
  dispatch,
  batchId,
  activeFilters,
  currencyIso,
  payload,
}: {
  dispatch: AppDispatch;
  batchId: string;
  activeFilters: BuyingFilters;
  currencyIso: string;
  payload: AssortmentEditable;
}) => {
  const { aggregation } = payload;

  dispatch(
    assortmentApi.util.updateQueryData(
      'getAssortmentData',
      {
        batchId,
        activeFilters,
        aggregation,
        currencyIso,
      },
      (draft) => {
        // loop through each of the changed product options

        payload.product_options.forEach((productOptionPayload) => {
          // find the changed product in the cache
          const foundProduct = draft.product_options.find(
            (draftProductOption) => {
              return (
                draftProductOption.product.id ===
                productOptionPayload.product_id
              );
            },
          );

          if (!foundProduct) {
            return { error: null };
          }

          // loop through each of the changed aggregation options
          productOptionPayload.aggregation_options.forEach(
            (aggregationOptionPayload) => {
              const foundAggregation = foundProduct.aggregation_options.find(
                (aggregationOption) => {
                  return (
                    `${aggregationOption?.id}-${aggregationOption?.description}` ===
                    `${aggregationOptionPayload?.id}-${aggregationOptionPayload?.description}`
                  );
                },
              );

              if (!foundAggregation) {
                return { error: null };
              }

              if (aggregationOptionPayload.selected) {
                foundAggregation.selected = 'selected';
              } else if (aggregationOptionPayload.selected === false) {
                foundAggregation.selected = 'not-selected';
              }

              return undefined;
            },
          );

          // update the total selected for the found product
          foundProduct.total_selected = foundProduct.aggregation_options.filter(
            ({ selected }) =>
              selected === SELECTED_OPTIONS.SELECTED ||
              selected === SELECTED_OPTIONS.PARTIALLY_SELECTED,
          ).length;
        });
      },
    ),
  );
};
