export const MODULE_ENTERED = 'module entered';
export const PAGE_VIEW = 'page view';
export const FILTER = 'select filter';
export const SCOPE = 'select scope';
export const PRODUCT_RATING = 'product rating';
export const PRODUCT_ASSORTMENT = 'updated assortment';
export const UPDATE_LOCATION_ASSORTMENT = 'update location assortment';
export const ADD_PRODUCT_TO_REGION = 'update region assortment';
export const SORT_PRODUCTS = 'sort products';
export const RUN_PRODUCT_QUANTIFICATION = 'run product quantification';
export const RUN_SIZE_QUANTIFICATION = 'run size quantification';
export const PRODUCT_SUBMIT = 'submit product quantification';
export const SIZE_SUBMIT = 'submit size quantification';
export const FREEZE_PRODUCT = 'freeze product';
export const ADD_NEW_PRODUCT_TO_QUANTIFICATION =
  'add new product to quantificaiton';
export const ADD_PRODUCT_TO_LOCATION_QUANTIFICATION = 'add product to location';
export const SAVE_QUANTIFICATION_CHANGES = 'save quantification changes';
export const SAVE_SIZE_CHANGES = 'save sizing changes';
export const EXPORT_SIZE = 'export size';
export const IMPORT_SIZE = 'import size';
export const FULL_SCREEN = 'full screen';
export const CHANGE_DIMENSION = 'change dimension';
export const EXPORT_TO_EXCEL = 'export to excel';
export const SHARE_URL_BUTTON_CLICKED = 'share url button clicked';
export const PEAK_VIEW_CLICK = 'click on the peak view icon';
export const PEAK_VIEW_ALL_DETAILS =
  'click on view all details in peak view tooltip';
export const INCREMENT_CAROUSEL_PAGE = 'increment carousel page';
export const DECREMENT_CAROUSEL_PAGE = 'decrement carousel page';
export const SUBMISSION_EMAIL_RECIPIENT_ADDED =
  'submission email recipient added';
export const SUBMISSION_EMAIL_RECIPIENT_REMOVED =
  'submission email recipient removed';
export const COPY_BUTTON_CLICKED = 'copy product id';
export const TOGGLE_STICKY_KPI_POPOVER = 'toggle sticky kpi popover';
export const REMOVE_MARKDOWN_PRODUCTS = 'remove markdown products';
export const IGNORE_MINIMUM_INVENTORY_LEVEL = 'ignore minimum inventory level';
export const UPDATE_DAYS_OF_WEEK = 'update days of week';
export const UPDATE_TIME = 'update schedule time';
export const UPDATE_FREQUENCY = 'update schedule frequency';
export const RUN_NOW_OR_FUTURE = 'update schedule to run now or in the future';
export const UPDATE_END_DATE = 'update schedule end date';
export const UPDATE_START_DATE = 'update schedule start date';
export const APPLY_SAVED_FILTERS = 'apply saved filters';
export const EDIT_SAVED_FILTER_NAME = 'edit saved filter name';
export const DELETE_SAVED_FILTER = 'delete saved filter';
export const SHOW_HIDE_PRODUCT_IMAGES = 'show hide product images';
// GENERIC EVENTS
export const TEXT_CHANGE = 'text change';
export const BUTTON_CLICK = 'button click';
export const EXPORT = 'export';
export const TOGGLE = 'toggle';
export const VIEW_ACCESSED = 'view accessed';
export const DROPDOWN_SELECT = 'dropdown select';
