// returns the error message to show on the front end on snackbar based on the error code of the request
import { type ErrorType } from './types';

const errorMessages = {
  400: 'You sent a bad request',
  401: 'You do not have permissions',
  500: 'Oops, something went wrong',
  404: 'Request not found',
};

const defaultMessage = 'An error occured';

export default (err?: ErrorType, customMessage?: string) =>
  customMessage ||
  (err && err?.response
    ? // @ts-ignore
      errorMessages?.[err?.response?.status] || defaultMessage
    : defaultMessage);
