import { useAutoneTranslation } from '@autone/translations';
import { CanAccess } from '@autone/ui';
import { BUYING_READONLY, DEBOUNCE_DELAY } from '@autone/utils';
import CreateIcon from '@mui/icons-material/Create';
import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { debounce } from 'lodash';
import { type FC, useCallback, useRef, useState } from 'react';

import { type APP_NAME, INITIAL_PRICE_TYPE } from '../../../constants';
import { usePatchBatchMutation } from '../../../redux/services';
import { type BatchWithEditableComparables } from '../../../redux/services/batches/batches';
import { store } from '../../../redux/store';

type Props = {
  batchInfo: BatchWithEditableComparables | undefined;
  isLoading: boolean;
  isFetching: boolean;
};

const StepHeaderTitle: FC<Props> = ({ batchInfo, isLoading, isFetching }) => {
  // the length of the input value is used to calculate the input width
  const [inputValue, setInputValue] = useState('');
  const { t } = useAutoneTranslation<typeof APP_NAME>();

  const { id, name, otb } = batchInfo || {};

  const inputRef = useRef<HTMLInputElement>(null);

  const [patchBatch] = usePatchBatchMutation();

  const { idToken: token } = store.getState().user;
  const isReadOnly = token ? CanAccess(token, [BUYING_READONLY]) : true;

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const debouncedPatchBatch = useCallback(
    debounce((value: string) => {
      if (id) {
        patchBatch({ name: value, batchId: id });
      }
    }, DEBOUNCE_DELAY),
    [batchInfo],
  );

  const handleInputChange = (value: string) => {
    if (value.length >= 1) {
      setInputValue(value);
      debouncedPatchBatch(value);
    }
  };

  return (
    <Stack>
      {isLoading || isFetching || !name ? (
        <Skeleton width={210} height={40} />
      ) : (
        <Stack direction="row">
          <InputBase
            disabled={isReadOnly}
            inputRef={inputRef}
            value={inputValue || name}
            sx={{
              fontSize: 'h3.fontSize',
              fontWeight: 500,
              width: `${(inputValue.length || name.length) * 14}px`,
              maxWidth: '300px',
              '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
                backgroundColor: 'transparent',
                '-webkit-text-fill-color': 'text.primary',
              },
            }}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
          {!isReadOnly && (
            <IconButton onClick={() => handleFocus()}>
              <CreateIcon sx={{ height: '24px', width: '24px' }} />
            </IconButton>
          )}
        </Stack>
      )}

      {isLoading || isFetching || !otb ? (
        <Skeleton width={210} height={20} />
      ) : (
        <Typography
          variant="body1"
          sx={{
            fontWeight: 300,
            visibility: otb.value ? 'visible' : 'hidden',
          }}
        >
          {t.withValue(`header.${otb.price_type || INITIAL_PRICE_TYPE}`, {
            value: otb.value,
            currency: otb.currency,
          })}
        </Typography>
      )}
    </Stack>
  );
};

export default StepHeaderTitle;
