import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type MetricId = 'rrp' | 'ws' | 'ic';

const useAvailablePriceTypes = (priceTypeToCheckAvailable?: MetricId) => {
  // @ts-ignore TODO: need to figure out how to type this as store is only present in the apps
  const { config } = useSelector((state) => state.user) as {
    config: { available_price_types?: { name: MetricId }[] };
  };

  const { available_price_types: availablePriceTypes } = config;

  const hasPriceAvailable = useMemo(() => {
    if (!priceTypeToCheckAvailable || !availablePriceTypes) return false;

    return availablePriceTypes
      .map(({ name }) => name)
      .includes(priceTypeToCheckAvailable);
  }, [availablePriceTypes, priceTypeToCheckAvailable]);

  return { availablePriceTypes, hasPriceAvailable };
};

export default useAvailablePriceTypes;
