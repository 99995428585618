/**
 * Formats a failed batch creation script as a markdown string.
 */
export const formatAutoneBatchCreationScriptErrorAsMd = (
  batchId: string,
  errorCode?: string | null,
): string => {
  // please do not modify indentation as it will be captured as whitespace in the markdown
  const message = `
**batch id**: ${batchId}
**error code**: ${errorCode ?? 'UNKNOWN_ERROR'}
`;
  return message;
};
