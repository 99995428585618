export const CircularTickSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1">
      <circle id="Ellipse 1" cx="16" cy="16" r="16" fill="#0267FF" />
      <path
        id="Vector"
        d="M11 16.3636L14.75 20L23 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
