import { useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';

import { type FullScreenContainer } from '../components/table/types';

const useFullScreen = (
  fullScreenCallback: (
    isFullScreen: boolean,
    fullScreenContainer: FullScreenContainer,
  ) => void = () => {},
) => {
  // whether full screen is active
  const [isFullScreen, setIsFullScreen] = useState(false);
  const handle = useFullScreenHandle();

  // set full screen mode
  const fullScreenChange = (d: boolean) => {
    setIsFullScreen(d);
    fullScreenCallback(d, handle.node);
  };

  const handleFullScreenMode = () => {
    if (isFullScreen) {
      return handle.exit();
    }
    if (!isFullScreen) {
      return handle.enter();
    }
    return null;
  };

  return { handleFullScreenMode, fullScreenChange, isFullScreen, handle };
};

export default useFullScreen;
