import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchUserAttributes } from 'aws-amplify/auth';

import {
  formatAutoneBackendErrorAsMd,
  formatAutoneBatchCreationScriptErrorAsMd,
  isAutoneBackendError,
} from '../api';
import { type ModuleName } from '../types';

import { notifySlackApp } from './notifySlackApp';
import {
  type KnownBlock,
  type SlackNotificationObject,
  type SlackWebhookUrl,
} from './types';
import { getPlatformAlertsSlackWebhookUrl } from './urls';

/**
 * Call a Slack Incoming Webhook with a footer containing the user's email address
 * and name from the current authenticated **AWS Cognito** session.
 */
const notifySlackAppWithUserInfoBase = async <URL extends string>(
  webhookUrl: SlackWebhookUrl<URL>,
  webhookBlocks: KnownBlock[],
) => {
  const { email, name } = await fetchUserAttributes();
  const response = await notifySlackApp(webhookUrl, {
    blocks: [
      ...webhookBlocks,
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `from account: *${name}* (${email})`,
        },
      },
    ],
  });
  return response;
};

export const slackAppWithUserInfo = {
  notifyUiCrash: async (
    app: Capitalize<ModuleName>,
    error: Error,
    stackTrace: string,
  ) => {
    return await notifySlackAppWithUserInfoBase(
      getPlatformAlertsSlackWebhookUrl(),
      [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `${app} Batch Creation UI Error`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `**Error**: ${error.message}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `**Stack Trace**\n${stackTrace}`,
          },
        },
      ],
    );
  },
  notifyBatchCreationFailed: async (
    app: Capitalize<ModuleName>,
    error: FetchBaseQueryError,
  ) => {
    return await notifySlackAppWithUserInfoBase(
      getPlatformAlertsSlackWebhookUrl(),
      [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `${app} Batch Creation Error`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: isAutoneBackendError(error.data)
              ? formatAutoneBackendErrorAsMd(error.data)
              : 'No detail provided',
          },
        },
      ],
    );
  },
  notifyBatchCreationScriptFailed: async (
    app: Capitalize<ModuleName>,
    batchId: string,
    errorCode?: string | null,
  ) => {
    return await notifySlackAppWithUserInfoBase(
      getPlatformAlertsSlackWebhookUrl(),
      [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `${app} Batch Creation Script Error`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: formatAutoneBatchCreationScriptErrorAsMd(batchId, errorCode),
          },
        },
      ],
    );
  },
} satisfies SlackNotificationObject;
